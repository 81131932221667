<template>
    <div>
        <v-container fluid v-if="loaded">
            <v-row class="product-max-width">
                <v-col cols="12" sm="6" class="px-0 py-0" style="background-color: white">
                    <v-carousel height="600" hide-delimiter-background>
                        <v-carousel-item v-for="(image,i) in producto.galeria" :key="'galeria-'+i">
                            <v-img :src="'https://content.elmurcielagotequileria.com/'+image.path" aspect-ratio="1" :contain="true" height="600"></v-img>
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col cols="12" sm="6" class="text-center px-0 py-0">
                    <div style="height: 435px">
                        <h1 class="pt-8">{{producto.nombre}}</h1>
                        <span class="d-block italic">{{producto.categoria}} {{producto.clase.name}}</span>

                        <div class="mt-5 mb-5">
                            <template v-for="(presentacion, pres) in presentations">
                                <div class="d-inline-block pa-4 pointer mx-2 presentaciones" v-bind:class="{'presentacionSeleccionada':selected == pres}" :key="'presentacion-'+pres" @click="selected = pres">
                                    <span class="d-block bold titles">${{numberWithCommas(presentacion.precio)}}</span>
                                    <span class="d-block italic">Cont. Net. <span class="bold">{{presentacion.contenido}}</span></span>
                                </div>
                            </template>
                        </div>
                        <template v-if="cantidadDisponible.length > 0">
                        
                            <div>
                                <v-row align="center" justify="center">
                                    <v-col cols="auto">
                                        <span class="d-inline-block">Cantidad:</span>
                                    </v-col>
                                    <v-col cols="auto" style="max-width: 100px">
                                        <v-select
                                            outlined
                                            :items="cantidadDisponible"
                                            :hide-details="true"
                                            color="black"
                                            item-color="green"
                                            v-model="cantidad"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="mt-5" v-if="this.producto.activo">
                                    <v-col cols="auto">
                                        <v-btn elevation="0" dark color="#A06A4C" @click="addToCart()" class="btn-carrito">Agregar al carrito</v-btn>
                                    </v-col>
                                </v-row>
                            </div>

                        </template>

                    </div>
                    <div v-if="!$vuetify.breakpoint.xs" style="height: 165px; background-color: #EDE4CB; position: relative;">
                        <carousel :dots="false" :nav="false" :items="1" :slideBy="3">
                            <div>
                                <v-row class="mt-8 pt-0">
                                    <v-col class="text-center">
                                        <span class=" d-block titulo-caracteristicas-producto">Clase</span>
                                        <span class="italic bold d-block other-text">{{producto.clase.name}}</span>
                                    </v-col>
                                    <v-col class="text-center">
                                        <span class=" d-block titulo-caracteristicas-producto">Categoría</span>
                                        <span class="italic bold d-block other-text">{{producto.categoria}}</span>
                                    </v-col>
                                    <v-col class="text-center">
                                        <span class=" d-block titulo-caracteristicas-producto">Grados de Alcohol</span>
                                        <span class="italic bold d-block other-text">{{producto.grados}}</span>
                                    </v-col>
                                </v-row>
                            </div>
                            <div>
                                <v-row class="mt-8 pt-0">
                                    <v-col class="text-center">
                                        <span class="bold d-block titulo-caracteristicas-producto">Región</span>
                                        <span class="italic bold d-block">{{producto.region.name}}</span>
                                    </v-col>
                                    <v-col class="text-center">
                                        <span class="bold d-block titulo-caracteristicas-producto">Agave</span>
                                        <span class="italic bold d-block">{{producto.agave.name}}</span>
                                    </v-col>
                                    <v-col class="text-center">
                                        <span class="bold d-block titulo-caracteristicas-producto">Producción</span>
                                        <span class="italic bold d-block">{{producto.produccion}}</span>
                                    </v-col>
                                </v-row>
                            </div>
                            <template slot="prev">
                                <div class="next" style="position: absolute; bottom: 30px; left:50%; transform: translateX(-50%);">
                                    <v-icon class="pointer">mdi-arrow-right</v-icon>
                                </div>
                            </template>
                            <template slot="next">
                                <div class="next" style="position: absolute; bottom: 30px; left:50%; transform: translateX(-50%);">
                                    <v-icon class="pointer">mdi-arrow-right</v-icon>
                                </div>
                            </template>
                        </carousel>
                    </div>
                </v-col>
            </v-row>

            <v-row class="mt-0">
                <v-col class="py-8 py-sm-12  text-center">
                    <span class="bold titles">También te puede interesar...</span>
                </v-col>
            </v-row>
        </v-container>

        <div style="background-color: #151616;">
            <v-container>
                <v-row class="py-12">
                    <!-- <v-col class="py-0 text-center white--text" cols="3" v-for="(similares, s) in productos" :key="'producto-similar'+s">
                        <router-link :to="'/tienda/productos/'+similares.url">
                            <v-img :src="'https://content.elmurcielagotequileria.com/'+similares.cover.path"></v-img>
                        </router-link>

                        <router-link :to="'/tienda/productos/'+similares.url" class="not-decorated white--text">
                            <span class="d-block bold">{{similares.categoria}} {{similares.nombre}}</span>
                        </router-link>
                        <router-link :to="'/tienda/productos/'+similares.url" class="not-decorated white--text" >
                            <span class="d-block">{{similares.clase.name}}</span>
                        </router-link>
                    </v-col> -->
                    <template  v-for="(similares, s) in prodsSimilares">
                        <v-col cols="12" sm="4" md="3" :key="'producto-similar-'+s" class="text-center mb-7" v-if="s <= 3">
                            <div class="d-block mx-auto" style="max-width: 250px">
                                <router-link :to="'/tienda/productos/'+similares.url">
                                    <v-img :src="'https://content.elmurcielagotequileria.com/'+similares.cover.path" aspect-ratio="1" contain class="img-producto"></v-img>
                                </router-link>
                                <router-link :to="'/tienda/productos/'+similares.url" class="not-decorated">
                                    <span class="d-block text-center white--text bold">{{similares.nombre}}</span>
                                </router-link>
                                <router-link :to="'/tienda/productos/'+similares.url" class="not-decorated">
                                    <span class="d-block text-center white--text">{{similares.clase.name}}</span>
                                </router-link>
                                <hr class="hr-custom">
                                <div class="white--text text-center precio-margin">
                                    <template v-if= "obtenerPresentaciones(producto._id).length > 0">
                                        Desde
                                        ${{numberWithCommas(obtenerPresentaciones(similares._id)[0].precio)}}
                                    </template>
                                </div>
                                <v-btn outlined class="btn-producto" color="#A06A4C" :to="'/tienda/productos/'+similares.url">
                                    <span class="white--text">Ver producto</span>
                                </v-btn>
                            </div>
                        </v-col>
                    </template>
                </v-row>
            </v-container>

        </div>

        <v-snackbar
            v-model="snackbarAgregado"
            top
            color="#ADC332"
            app
        >
            <span class="black--text">Agregado al carrito</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                color="black"
                icon
                v-bind="attrs"
                @click="snackbarAgregado = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'
import carousel from 'vue-owl-carousel'
Vue.use(VueSession, {persist: true})

export default {
    metaInfo() {
        return{     
            title: `${this.producto.nombre} | El Murciélago Tequilería`,
            // titleTemplate: 'Marisol Malibu',
            htmlAttrs: {
            lang: 'es'
            },
            meta: [
            {name:'description', content: `${this.producto.nombre} - ${this.producto.categoria} ${this.producto.produccion} ${this.producto.grados}`}
            ]
        }
    },
    components: { carousel },
    data () {
        return {
            producto :{
                clase :{
                    name: ''
                },
                nombre: ''
            },
            presentaciones: [],
            loaded: false,
            selected: 0,
            cantidad: 1,
            productos: [],
            snackbarAgregado: false
        }
    },
    beforeMount: function () {
        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Productos', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {url:this.$route.params.url},
                fields: {},
                populate: 1, // resolve linked collection items
            })
        })
        .then(res=>res.json())
        .then(res => this.producto = res.entries[0])
        .then(this.cargarPresentaciones())


        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Productos', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {
                    activo: true
                },
                fields: {},
                populate: 1, // resolve linked collection items
            })
        })
        .then(res=>res.json())
        .then(res => this.productos = res.entries)
        
    },
    methods:{
        obtenerPresentaciones: function (idProducto) {
            let p = this.presentaciones.filter(presentacion => presentacion.producto._id == idProducto);

            p.sort(function(a,b) {
                if(a.precio > b.precio){
                    return 1;
                }

                if(a.precio < b.precio){
                    return -1;
                }

                return 0
            })

            return p;
        },
        cargarPresentaciones: function () {
            fetch('https://content.elmurcielagotequileria.com/api/collections/get/Presentaciones', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    filter: {},
                    fields: {},
                    populate: 2, // resolve linked collection items
                })
            })
            .then(res=>res.json())
            .then(res => this.presentaciones = res.entries)
            .then(this.loaded = true)
        },
        numberWithCommas: function (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        addToCart: function () {
            let carrito = this.$session.get('emcart');
            let nuevoCarrito = [];

            var a = carrito.findIndex(elemento => elemento.id == this.presentations[this.selected]._id);

            if(a == -1){

                carrito.push({
                    id: this.presentations[this.selected]._id,
                    presentacion: this.presentations[this.selected],
                    cantidad: this.cantidad
                })

                for (let index = 0; index < carrito.length; index++) {
                    const element = carrito[index];
                    nuevoCarrito.push(element)
                }
            }else{
                for (let index = 0; index < carrito.length; index++) {
                    const element = carrito[index];
                    if(element.id == this.presentations[this.selected]._id){
                        element.cantidad = element.cantidad + this.cantidad;
                    }

                    nuevoCarrito.push(element);
                    
                }
            }

            this.snackbarAgregado = true;

            this.$session.set('emcart',nuevoCarrito)
            // carrito.push(this.presentations[this.selected])
            // this.$session.set('emcart',carrito);
            this.$emit('update');
        }
    },
    computed: {
        prodsSimilares: function () {
            let arreglo = []
            if(this.loaded){
                arreglo = this.productos.filter(prod => prod.categoria == this.producto.categoria && prod._id != this.producto._id)

                for (var i = arreglo.length - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1));
                    var temp = arreglo[i];
                    arreglo[i] = arreglo[j];
                    arreglo[j] = temp;
                }
            }

            return arreglo
        },
        presentations: function () {
            let p = [];
            const id = this.producto._id;

            for (let index = 0; index < this.presentaciones.length; index++) {
                const element = this.presentaciones[index];

                if(element.producto._id == id){
                    p.push(element);
                }
                
            }

            p.sort(function(a,b) {
                if(a.precio > b.precio){
                    return 1;
                }

                if(a.precio < b.precio){
                    return -1;
                }

                return 0
            })

            return p;
        },
        cantidadDisponible: function () {
            let n = [];
            if(this.presentations.length > 0){
                for (let index = 0; index < this.presentations[this.selected].unidadesDisponibles; index++) {
                    n.push(index+1);
                }   
            }else{
                n = [0];
            }
            return n;
        }
    },
    watch: {
        $route: function () {
            fetch('https://content.elmurcielagotequileria.com/api/collections/get/Productos', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    filter: {
                        url:this.$route.params.url
                    },
                    fields: {},
                    populate: 1, // resolve linked collection items
                })
            })
            .then(res=>res.json())
            .then(res => this.producto = res.entries[0])
            .then(this.cargarPresentaciones())

            this.$vuetify.goTo(0)
        },
        cantidadDisponible: function () {
            this.cantidad = 1
        }
    }
}
</script>